import React from 'react'
import ExpensePage from './ExpensePage'

export default function TripExpense(props) {
    return (
        <div>
            <ExpensePage user={props.user} isTrip={1} name='Trip Expense' />
        </div>
    )
}
