import React from "react";

import ExpPurReport from "../common/ExpPurReport";
import { BASE_URL } from "../../config";

export default function TripExpenseReport() {
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    fetchItems("", "");
  }, []);

  const fetchItems = (from, to, id) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_expense_report?to=" + to + "&from=" + from,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setItems(res.items);
      });
  };  

  return (
    <ExpPurReport
      items={items}
      name="Trip Expense"
      route="/trip_view/"
      fetchItems={fetchItems}
    />
  );
}
