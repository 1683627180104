import React from "react";
import "./LoginForm.css";
import { BASE_URL } from '../../config';

export default function LoginForm(props) {
  const [otpStep, setOtpStep] = React.useState(false);
  const [OTP, setOTP] = React.useState("");
  const [error, setError] = React.useState("")
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState({});

  const generateOTP = () => {
    fetch(BASE_URL+"/api/otp",  {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ 'user_id' : user }),
    })
      .then((res) => res.json())
      .then((res) => {
        setOtpStep(true);
      });
  };

  React.useEffect(() => {
    getUsers()
  }, [])

  const getUsers = () => {
    fetch(BASE_URL+"/api/users", { method: "GET" })
      .then((res) => res.json())
      .then((res) => {
        setUsers(res.users);
      });
  };

  const login = () => {
    fetch(BASE_URL+"/api/verify_otp", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ 'otp' : OTP, 'user_id' : user }),
    })
      .then((res) => res.json())
      .then((res) => {
        if(!error){
          let myStorage = window.localStorage;
          myStorage.setItem("fish-token", res.token);
          props.setIsLoggedIn(true); 
          props.setLogedInUser(JSON.parse(res.user)); 
        }else{
          setError(res.error)
        }
      });
  };

  return (
    <div class="form-div">
      <div className="form">
        {otpStep ? (
          <>
            <a onClick={() => setOtpStep(false)}> Go Back </a>
            <p>OTP </p>
            <input
              onChange={(event) => setOTP(event.target.value)}
              value={OTP}
              className="otp"
              type="number"
            />
            <p style={{color: 'red', fontSize: 12}}>{error}</p>
          </>
        ) : (
          <>
          <select value={user.id} onChange={(e) => setUser(e.target.value)} >
          <option value='0'></option>
            {users.map(u => (
              <option value={u.id}>{u.name}</option>
            ))}
          </select>
          </>
        )}
        <button onClick={otpStep ? login : generateOTP}>
          {otpStep ? "Login" : "GENERATE OTP"}
        </button>
      </div>
    </div>
  );
}
