import React from 'react'
import ExpensePage from './ExpensePage'

export default function GeneralExpense(props) {
    return (
        <div>
            <ExpensePage user={props.user} isTrip={0} name="General Expense" />
        </div>
    )
}
