import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Pagination from "@material-ui/lab/Pagination";
import { BASE_URL , IMG_URL } from "../../config";

import ResponisveDialog from "./ResponsiveDialog";

export default function ItemTable(props) {
  const [searchValue, setSearchValue] = React.useState("");
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  const [open, setOpen] = React.useState(false);

  const ref = React.useRef();

  React.useEffect(() => {
    fetchData();
  }, [page,props.trip_id]);

  const editClick = (item) => {
    let newFormValues = { ...props.formValues };
    newFormValues["amount"] = item.amount;
    newFormValues["details"] = item.details;
    newFormValues["date"] = new Date(item.date);
    newFormValues["img_src"] = item.img_src;
    if(props.trip_id){
      newFormValues["trip_id"] = props.trip_id;
    }
    newFormValues["expense_id"] = {
      label: item.expense.name,
      value: item.expense.id,
    };
    newFormValues["is_credit"] = item.is_credit;

    newFormValues["item_id"] = item.id;
    props.setFormValues(newFormValues);
    props.setOpen(true);
  };

  const createBtnClick = (item) => {
    props.setOpen(true);
    props.resetForm();
  };

  const fetchData = () => {
    let tk = window.localStorage.getItem("fish-token");
    let trip_id = '';
    props.trip_id ? trip_id = props.trip_id : trip_id = ''
    fetch(
      BASE_URL +
        "/api/general_expenses?search=" +
        searchValue +
        "&page=" +
        page+
        "&trip_id=" +
        trip_id,
      {
        headers: {
          "x-access-token": tk,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        props.setItems(JSON.parse(res.items));
        setPageCount(res.pages);
      });
  };

  const search = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchData();
  };

  const handleChoice = (choice) => {
    setOpen(false);
    if (choice) {
      sendDeleteRequest(rowToDelete);
      setRowToDelete({});
    }
  };

  const sendDeleteRequest = (item) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/general_expense/" + item.id, {
      method: "DELETE",

      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchItems();
      });
  };

  let total = 0;

  return (
    <>
      <ResponisveDialog open={open} handleChoice={handleChoice} />
      <TableContainer className="table" component={Paper}>
        <Table>
          {props.trip_id ? (
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} className="bold" align="center">
                  {props.user.type == "admin" && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<AddCircleIcon />}
                      onClick={createBtnClick}
                    >
                      {props.trip_id ? 'Less General Expense' : 'Create'}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="bold-row"></TableRow>
            </TableHead>
          ) : (
            <TableHead>
              <TableRow>
                <TableCell className="bold" align="left">
                  <p className="td-p m0">General</p>
                  <p className="td-p m0">Expense</p>
                  <p className="center m0">Total : {props.data.length}</p>
                </TableCell>
                <TableCell className="bold" align="center">
                  <div className="search-div">
                    <InputBase
                      ref={ref}
                      onKeyPress={(event) => search(event)}
                      onChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue}
                    />
                  </div>
                </TableCell>
                <TableCell className="bold" align="center">
                  {props.user.type == "admin" && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<AddCircleIcon />}
                      onClick={createBtnClick}
                    >
                      Create
                    </Button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="bold-row"></TableRow>
            </TableHead>
          )}
          <TableBody className="tbody-lg">
            {props.data.map((row) => {
              total += row.amount;
              return(
              
              <TableRow key={row.id}>
                <TableCell className="hidden" component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">
                  <p className="m3">{row.expense.name}</p>
                  <p className="m3">
                    {new Date(row.date).toLocaleDateString("en-IN")}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="m3">₹ {row.amount}</p>
                  <p className="m3">{row.details}</p>
                  <p className="m3">{row.is_credit ? "Credit" : "Cash"}</p>
                  <img
                    style={{ width: "100px", borderRadius: "10%" }}
                    src={IMG_URL + "/" + row.img_src}
                    alt=""
                  />
                </TableCell>

                <TableCell align="center" className="action-btn-td">
                  {props.user.type == "admin" && (
                    <>
                      <EditIcon onClick={() => editClick(row)} />
                      <DeleteIcon
                        onClick={() => {
                          setOpen(true);
                          setRowToDelete(row);
                        }}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>
      {props.trip_id && props.setTotal(total)}
      {!props.trip_id &&
      <Pagination
        count={pageCount}
        color="primary"
        className="table"
        onChange={handlePageChange}
      />
}
    </>
  );
}
