import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Pagination from "@material-ui/lab/Pagination";
import { BASE_URL ,  IMG_URL } from "../../config";

import ResponisveDialog from "./ResponsiveDialog";

export default function ItemTable(props) {
  const [searchValue, setSearchValue] = React.useState("");
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  const [open, setOpen] = React.useState(false);

  const [note, setNote] = React.useState("");

  const ref = React.useRef();

  React.useEffect(() => {
    fetchData();
    getNote();
  }, [page]);

  const editClick = (item) => {
    let newFormValues = { ...props.formValues };
    newFormValues["amount"] = item.amount;
    newFormValues["details"] = item.details;
    newFormValues["date"] = new Date(item.date);
    newFormValues["img_src"] = item.img_src;
    newFormValues["partner_id"] = item.partner.id;

    newFormValues["item_id"] = item.id;
    props.setFormValues(newFormValues);
    props.setOpen(true);
  };

  const createBtnClick = (item) => {
    props.setOpen(true);
    props.resetForm();
  };

  const fetchData = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(
      BASE_URL +
        "/api/partner_investments?search=" +
        searchValue +
        "&page=" +
        page,
      {
        headers: {
          "x-access-token": tk,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        props.setItems(JSON.parse(res.items));
        setPageCount(res.pages);
      });
  };

  const createNote = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/partner_investment_note", {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": tk,
      },
      method: "POST",
      body: JSON.stringify({ note: note }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      });
  };

  const getNote = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/partner_investment_note", {
      headers: {
        "x-access-token": tk,
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setNote(res.note);
      });
  };

  const search = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchData();
  };

  const handleChoice = (choice) => {
    setOpen(false);
    if (choice) {
      sendDeleteRequest(rowToDelete);
      setRowToDelete({});
    }
  };

  const sendDeleteRequest = (item) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/partner_investment/" + item.id, {
      method: "DELETE",

      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchItems();
      });
  };

  return (
    <>
      <ResponisveDialog open={open} handleChoice={handleChoice} />
      <TableContainer className="table" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="bold" align="left">
                <p className="td-p m0">Partner</p>
                <p className="td-p m0">Investment</p>
                <p className="center m0">Total : {props.data.length}</p>
              </TableCell>
              <TableCell className="bold" align="center">
                <div className="search-div">
                  <InputBase
                    ref={ref}
                    onKeyPress={(event) => search(event)}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                </div>
              </TableCell>
              <TableCell className="bold" align="center">
                {props.user.type == "admin" && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddCircleIcon />}
                    onClick={createBtnClick}
                  >
                    Create
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow className="bold-row"></TableRow>
          </TableHead>
          <TableBody className="tbody-lg">
            {props.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell className="hidden" component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">
                  <p className="m3">{row.partner.name}</p>
                  <p className="m3">
                    {new Date(row.date).toLocaleDateString("en-IN")}
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="m3">₹ {row.amount}</p>
                  <p className="m3">{row.details}</p>
                  <img
                    style={{ width: "100px", borderRadius: "10%" }}
                    src={IMG_URL + "/" + row.img_src}
                    alt=""
                  />
                </TableCell>

                <TableCell align="center" className="action-btn-td">
                  {props.user.type == "admin" && (
                    <>
                      <EditIcon onClick={() => editClick(row)} />

                      <DeleteIcon
                        onClick={() => {
                          setOpen(true);
                          setRowToDelete(row);
                        }}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan="3">
                <textarea
                  style={{ fontSize: 16 }}
                  rows="20"
                  cols="25"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                >
                  {note}
                </textarea>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={createNote}
                >
                  Add Note
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={pageCount}
        color="primary"
        className="table"
        onChange={handlePageChange}
      />
    </>
  );
}
