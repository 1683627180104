import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PrintIcon from "@material-ui/icons/Print";
import ImageIcon from "@material-ui/icons/Image";
import { Link } from "react-router-dom";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { BASE_URL ,  IMG_URL } from "../../config";

export default function Ledger({ neg, pos, name, ledger, fetchItems, quantity, prev_pos, prev_neg }) {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState({});

  let bal = neg - pos;

  const imageClick = (item) => {
    setOpen(true);
    setItem(item);
  };

  let last_amount = 0;

  return (
    <div style={{marginBottom: 150}} >
      <Modal
        style={{ overflow: "auto" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <div className="modal">
            <img src={IMG_URL + "/" + item.img_src} />
          </div>
        </Fade>
      </Modal>
      <table
        class="border-table"
        style={{ maxWidth: "900px", margin: "10px auto", }}
      >
        <tbody>
         
          <tr>
            <td
              colSpan="2"
              style={{
                textAlign: "center",
                padding: 10,
                fontSize: 30,
              }}
            >
              <AssignmentIcon /> {name}
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 10,
                fontSize: 30,
              }}
            >
              <PrintIcon onClick={() => window.print() }className="print-icon" />
            </td>
          </tr>
          {prev_pos - prev_neg != 0 && (
            <tr className="tr">
            <td>Prev</td>
            <td className="lg-amt red">₹ {prev_pos}</td>
            <td className="lg-amt green">₹ { prev_neg}</td>
          </tr>
          )}
          
          <tr className="tr ">
            <td
              colSpan="3"
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <p className="m3">Bal : ₹ {neg - pos}</p>
              <p className="m3">Quantity : {quantity && quantity}</p>
            </td>
          </tr>
           <tr className="tr">
            <td></td>
            <td className="lg-amt red">₹ {pos - prev_pos}</td>
            <td className="lg-amt green">₹ {neg - prev_neg}</td>
          </tr> 
          
          {ledger.map((item) => {
            bal = bal - last_amount;
            return (
              <tr className={!item.isLeft ? "tr tr-left" : "tr tr-right"}>
                <td className="date-td">
                  <p className="bal m0">bal : ₹ {bal}</p>

                  <p className="m3">
                    {" "}
                    {new Date(item.date).toLocaleDateString('en-IN')}
                  </p>

                  {item.isLeft
                    ? <span className="hidden" >{last_amount = item.amount}</span>
                    : <span className="hidden" >{last_amount = -1 *  item.amount}</span>}

                  <p className="ext m0">{item.details}</p>
                  <p className="ext m0">
                    {item.fish}{" "}
                    {item.quantity ? (
                      <>
                         {item.quantity} {item.unit} ₹ {item.rate}
                      </>
                    ):""}
                  </p>
                  {item.trip_id && (
                    <Link style={{color: 'dodgerblue'}} to={"/trip_view/" + item.trip_id}>
                    {item.trip_name} <br />
                  </Link>
                  )}
                  
                  {item.img_src ? <ImageIcon onClick={() => imageClick(item)} className="print-icon" /> : ''  }
                </td>
                {!item.isLeft ? (
                  <>
                    <td>
                      <p className="amt m0"> ₹ {item.amount}</p>
                    </td>
                    <td></td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td>
                      <p className="amt m0"> ₹ {item.amount}</p>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
          <tr className="tr ">
            <td
              colSpan="3"
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <p className="m3"> Opening Bal : ₹ {bal - last_amount}</p>
            </td>
          </tr>
          <tr className="tr">
            <td></td>
            <td className="lg-amt red">₹ {pos - prev_pos}</td>
            <td className="lg-amt green">₹ {neg - prev_neg}</td>
          </tr> 
          <tr className="tr">
            <td className="lg-amt"></td>
            <td className="lg-amt"></td>
            <td className="lg-amt">₹ {neg - pos}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
