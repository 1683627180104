import React from "react";

import { BASE_URL } from "../../config";
import TandelPage1 from "./TandelPage1";
import TandelPage2 from "./TandelPage2";
import "../../Card.css";

function TandelPage() {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [isNew, setIsNew] = React.useState(true);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/t", {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
        setLoading(false);
      });
  };

  if (loading) {
    return <p>Loading</p>;
  }

  return(
    <div className="container">
      <button className="toggle-btn"  onClick={() => setIsNew(!isNew)}> Format</button>
      {isNew ? <TandelPage1 data={data}/> : <TandelPage2 data={data}/>}
    </div>
  )



 
}

export default TandelPage;
