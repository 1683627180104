import React from "react";

import ExpenseForm from "./ExpenseForm";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const initFormValues = {
  id: "",
  name: "",
  bal: "",
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ExpenseCreate({open, setOpen, fetchItems, isTrip}) {
  const [formValues, setFormValues] = React.useState(initFormValues);
  

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [formMessage, setFormMessage] = React.useState("");
  const [formSeverity, setFormSeverity] = React.useState("success");

  const resetForm = () => {
    setFormValues(initFormValues);
    setOpen(true);
  };

  return (
    <div>
      <Modal
        style={{ overflow: "auto" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <div className="modal">
            <ExpenseForm
              resetForm={resetForm}
              fetchItems={fetchItems}
              setFormValues={setFormValues}
              formValues={formValues}
              setFormMessage={setFormMessage}
              setSnackBarOpen={setSnackBarOpen}
              setFormSeverity={setFormSeverity}
              setOpen={setOpen}
              isTrip={isTrip}
            />
          </div>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert onClose={() => setSnackBarOpen(false)} severity={formSeverity}>
          {formMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ExpenseCreate;
