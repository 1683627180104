import React from "react";

import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import InputLabel from "@material-ui/core/InputLabel";

import { BASE_URL } from '../../config';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function ItemForm({
  setFormValues,
  formValues,
  fetchItems,
  resetForm,
  setFormMessage,
  setFormSeverity,
  setSnackBarOpen,
  setOpen,
  isTrip,
}) {
  const [error, setError] = React.useState({});
  
  const changeFormDate = (date) => {
    let trip_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return trip_date;
  };

  const sendData = () => {
    let method = "POST";
    let data = new FormData();

    if(isTrip != 'option'){
      formValues['is_trip'] = isTrip;
    }

    for (let key in formValues) {
        if(key != 'doj' && key != 'dol'){
          data.append(key, formValues[key]);
        }
    }

    if (formValues.item_id) {
      method = "PUT";
    }

    if(formValues.doj){
      data.append('doj', changeFormDate(formValues.doj));
    }

    if(formValues.dol){
      data.append('dol', changeFormDate(formValues.dol));
    }
    
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/expense", {
      method: method,
      body: data,
        headers: {
          "x-access-token": tk,
        },
      
    })
      .then((res) => res.json())
      .then((res) => {
        let verb = "Insert";
        if (formValues.item_id) {
          verb = "Update";
        }
        if (res.error) {
          setError(res.error);
          setFormMessage(verb + " Error.");
          setFormSeverity("error");
          setSnackBarOpen(true);
        } else {
          setFormMessage(verb + " Complete.");
          setFormSeverity("success");
          setSnackBarOpen(true);
          resetForm();
          setError({});
          fetchItems();
          setOpen(false);
        }
      });
  };

  const handleFormChange = (key, value) => {
    let newFormValues = { ...formValues };
    newFormValues[key] = value;
    setFormValues(newFormValues);
  };


  return (
    <div>
      <div className="paper">
        <TextField
          value={formValues.name}
          error={error["name"] ? true : false}
          onChange={(e) => handleFormChange("name", e.target.value)}
          className="input"
          label="Name"
          variant="outlined"
          helperText={
            error["name"] ? error.name.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>

      { isTrip == "0" && 
      <div className="paper">
        <TextField
          value={formValues.salary}
          error={error["salary"] ? true : false}
          onChange={(e) => handleFormChange("salary", e.target.value)}
          className="input"
          label="Salary Per Month"
          variant="outlined"
          helperText={
            error["salary"] ? error.salary.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>}

      { isTrip == "0" && 
      <div className="paper">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={error["doj"] ? true : false}
          helperText={
            error["doj"] ? error.doj.map((e) => <span>{e}</span>) : ""
          }
          variant="outline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Labour Date of Joining"
          value={formValues.doj}
          onChange={(doj) => handleFormChange("doj", doj)}
        />
      </MuiPickersUtilsProvider>
    </div>
      }

  { isTrip == "0" && 
      <div className="paper">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={error["dol"] ? true : false}
          helperText={
            error["dol"] ? error.dol.map((e) => <span>{e}</span>) : ""
          }
          variant="outline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Labour Date of Leaving"
          value={formValues.dol}
          onChange={(dol) => handleFormChange("dol", dol)}
        />
      </MuiPickersUtilsProvider>
    </div>
      }
      

      { isTrip == "option" && 
      <div className="paper">
        <FormControl
          error={error["is_trip"] ? true : false}
          className="input"
          variant="outlined"
        >
          <InputLabel>Type</InputLabel>
          <Select
            className="input"
            value={formValues.is_trip}
            onChange={(e) => handleFormChange("is_trip", e.target.value)}
            label="Type"
            helperText={
              error["is_trip"]
                ? error.is_trip.map((e) => <span>{e}</span>)
                : ""
            }
          >
              <MenuItem value={1}>Trip</MenuItem>
              <MenuItem value={0}>General</MenuItem>
          </Select>
        </FormControl>
      </div> }

      <div className="paper">
        <FormControl
          error={error["typ"] ? true : false}
          className="input"
          variant="outlined"
        >
          <InputLabel>Type (optional)</InputLabel>
          <Select
            className="input"
            value={formValues.typ}
            onChange={(e) => handleFormChange("typ", e.target.value)}
            label="Type"
            helperText={
              error["typ"]
                ? error.typ.map((e) => <span>{e}</span>)
                : ""
            }
          >
              <MenuItem value={'diesel'}>diesel</MenuItem>
              <MenuItem value={'ice'}>ice</MenuItem>
              <MenuItem value={'tan:6'}>tan:6</MenuItem>
              <MenuItem value={'tan:3'}>tan:3</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="paper">
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={formValues.item_id ? <UpdateIcon /> : <SaveIcon />}
          onClick={sendData}
        >
          {formValues.item_id ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
}
