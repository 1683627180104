import React from "react";

import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BASE_URL } from '../../config';

export default function ItemForm({
  setFormValues,
  formValues,
  fetchItems,
  resetForm,
  setFormMessage,
  setFormSeverity,
  setSnackBarOpen,
  setOpen,
  purchasers,
}) {
  const [error, setError] = React.useState({});

  const sendData = () => {
    let method = "POST";
    let data = new FormData();

    let newFormValues = { ...formValues };

    newFormValues.date = changeFormDate(formValues.date);

    for (let key in newFormValues) {
      data.append(key, newFormValues[key]);
    }

    data.append(
      "image_file",
      document.getElementById("icon-button-file").files[0]
    );

    if (formValues.item_id) {
      method = "PUT";
    }

    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/partner_payment", {
      method: method,
      body: data,
      
        headers: {
          "x-access-token": tk,
        },
      
    })
      .then((res) => res.json())
      .then((res) => {
        let verb = "Insert";
        if (formValues.item_id) {
          verb = "Update";
        }
        if (res.error) {
          setError(res.error);
          setFormMessage(verb + " Error.");
          setFormSeverity("error");
          setSnackBarOpen(true);
        } else {
          setFormMessage(verb + " Complete.");
          setFormSeverity("success");
          setSnackBarOpen(true);
          resetForm();
          setError({});
          fetchItems();
          setOpen(false);
        }
      });
  };

  const changeFormDate = (date) => {
    let trip_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return trip_date;
  };

  const handleFormChange = (key, value) => {
    let newFormValues = { ...formValues };
    newFormValues[key] = value;
    setFormValues(newFormValues);
  };

  return (
    <div>
      <div className="paper">
        <FormControl
          error={error["partner_id"] ? true : false}
          className="input"
          variant="outlined"
        >
          <InputLabel>Partner</InputLabel>
          <Select
            className="input"
            value={formValues.partner_id}
            onChange={(e) => handleFormChange("partner_id", e.target.value)}
            label="Partner"
            helperText={
              error["partner_id"]
                ? error.partner_id.map((e) => <span>{e}</span>)
                : ""
            }
          >
            {purchasers.map((ex) => (
              <MenuItem value={ex.id}>{ex.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="paper">
        <TextField
          value={formValues.amount}
          error={error["amount"] ? true : false}
          onChange={(e) => handleFormChange("amount", e.target.value)}
          className="input"
          label="Amount"
          variant="outlined"
          helperText={
            error["amount"] ? error.amount.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>

      <div className="paper">
        <TextField
          value={formValues.details}
          error={error["details"] ? true : false}
          onChange={(e) => handleFormChange("details", e.target.value)}
          className="input"
          label="Details"
          variant="outlined"
          helperText={
            error["details"] ? error.details.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>

      <div className="paper">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={error["date"] ? true : false}
            helperText={
              error["date"] ? error.date.map((e) => <span>{e}</span>) : ""
            }
            variant="outline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Trip Expense Date"
            value={formValues.date}
            onChange={(date) => handleFormChange("date", date)}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div
        className="paper"
        
      >
        <input
          accept="image/*"
          id="icon-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={(e) =>
            handleFormChange("img_src", URL.createObjectURL(e.target.files[0]))
          }
        />
        <img  style={{maxWidth:"200px"}} src={formValues["img_src"]} alt="" />
        <label htmlFor="icon-button-file">
          <InputLabel>Upload Image</InputLabel>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
        {error["img_src"]
          ? error.img_src.map((e) => (
              <span style={{ color: "red", fontSize: "12px" }}>{e}</span>
            ))
          : ""}
      </div>

      <div className="paper">
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={formValues.item_id ? <UpdateIcon /> : <SaveIcon />}
          onClick={sendData}
        >
          {formValues.item_id ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
}
