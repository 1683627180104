import React from "react";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { BASE_URL ,  SEAANGEL, IMG_URL } from "../../config";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ImageIcon from "@material-ui/icons/Image";

export default function TripExpRow({ exp, setOpen, setRowToDelete,editClick, msg, user, setItem, setImgOpen }) {

  return (
    <>
      <TableRow key={exp.id} >
        <TableCell className="p0" className="hidden" component="th" scope="row">
          {exp.id}
        </TableCell>
        <TableCell className="p0" align="center">
          {exp.expense && <p className="f20 b m3">{msg} {exp.expense.name}</p>}
          <p className="m3 grey">
            {new Date(exp.date).toLocaleDateString("en-IN")}
          </p>
        </TableCell>
        <TableCell className="p0" align="center">
          {exp.rate ? (
            <p className="f14 m3">
              ₹ {exp.rate} x {exp.quantity} {exp.unit}{" "}
            </p>
          ) : (
            <p></p>
          )}
          <p className="f20 b m3 red">₹ {exp.amount}</p>
          {exp.details != "-" ? <p>{exp.details}</p> : ""}
          {exp.img_src && <ImageIcon onClick={() => {setItem(exp);setImgOpen(true)}} />}
          
        </TableCell>

        <TableCell align="center" className="action-btn-td p0">
        {user.type == "admin" && (<>
        <EditIcon onClick={() => editClick(exp)} />
          <DeleteIcon
            onClick={() => {
              setOpen(true);
              setRowToDelete(exp);
            }}
          />
          </>)}  
        </TableCell>
      </TableRow>
    </>
  );
}
