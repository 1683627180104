import React from "react";

import Admin from "./admin/Admin";
import "./App.css";

function App(props) {
  return (
    <Admin />
  );
}

export default App;
