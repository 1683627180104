import React from "react";

import { useParams } from "react-router-dom";
import Ledger from "../common/Ledger";
import Button from "@material-ui/core/Button";

import { BASE_URL } from "../../config";

export default function ExpenseLedger() {
  const { expense_id } = useParams();

  const [ledger, setLedger] = React.useState([]);
  const [neg, setNeg] = React.useState("");
  const [pos, setPos] = React.useState("");
  const [name, setName] = React.useState("");
  const [prev_pos, setPrevPos] = React.useState("");
  const [prev_neg, setPrevNeg] = React.useState("");
  const [quantity, setQuantity] = React.useState(0);

  React.useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = (from, to) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(
      BASE_URL +
        "/api/expense_ledger/" +
        expense_id +
        "?to=" +
        to +
        "&from=" +
        from,
      {
        headers: {
          "x-access-token": tk,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLedger(res.ledger);
        setPos(res.pos);
        setNeg(res.neg);
        setName(res.name);
        setQuantity(res.quantity);
        setPrevPos(res.prev_pos);
        setPrevNeg(res.prev_neg);
      });
  };

  const nextOrBack = (pos) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/expense_nxp/" + expense_id+"/"+pos, {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.href = "/expense_ledger/" + res.id;
      });
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
      <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => nextOrBack(0)}
        >
          Prev
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => nextOrBack(2)}
        >
          Next
        </Button>
       
      </div>

      <Ledger
        prev_neg={prev_neg}
        prev_pos={prev_pos}
        name={name}
        pos={pos}
        neg={neg}
        ledger={ledger}
        fetchItems={fetchItems}
        quantity={quantity}
      />
    </>
  );
}
