import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";

export default function ExpPurReport({ name, items, fetchItems, route }) {
  const [to, setTo] = React.useState("");
  const [from, setFrom] = React.useState("");

  let total = 0;

  const renderItems = () => {
    let list = [];
    total = 0;
    let amt = 0;
    for (let i = 0; i < items.length; i++) {
      let gen_amount = 0;

      if(items[i].amount){
        amt = items[i].amount;
      }
      if(items[i].gen_amount){
        gen_amount = items[i].gen_amount;
      }
      total +=  amt + gen_amount;
      list.push(
        <tr className="tr">
          <td>{new Date(items[i].date).toLocaleDateString("en-IN")} </td>
          <td>
            <Link style={{color: 'dodgerblue'}} to={route + items[i].id}>
              {items[i].name} <br />
            </Link>
          </td>
          <td>
            {gen_amount}<br/>
            {amt != 0 && amt}<br/>
            <b>₹ {amt + gen_amount}<br/></b>
            </td>
        </tr>
      );
    }
    return list;
  };


  return (
    <div style={{ marginBottom: 150 }}>
      <table
        class="border-table"
        style={{ maxWidth: "900px", margin: "10px auto" }}
      >
        <tbody>
          <tr className="tr ">
            <td
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <input
                type="date"
                value={from}
                style={{ maxWidth: 100 }}
                onChange={(e) => setFrom(e.target.value)}
              />
            </td>
            <td
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <input
                type="date"
                style={{ maxWidth: 100 }}
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </td>
            <td
              onClick={() => fetchItems(from, to)}
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <SearchIcon />
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              style={{
                textAlign: "center",
                padding: 10,
                fontSize: 30,
              }}
            >
              <AssignmentIcon /> {name}
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 10,
                fontSize: 30,
              }}
            >
              <PrintIcon onClick={() => window.print() }className="print-icon" />
            </td>
          </tr>
          
          {renderItems()}
          <tr className="tr" >
            <td></td>
            <td className="b f20" >Total</td>
              <td className="b f20" style={{padding: 10 }}colSpan="3">
              ₹ {total}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
