import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Pagination from "@material-ui/lab/Pagination";
import AssignmentIcon from '@material-ui/icons/Assignment';

import { BASE_URL } from '../../config';

import { useHistory } from "react-router-dom";


import ResponisveDialog from "./ResponsiveDialog";

export default function ItemTable(props) {
  const [searchValue, setSearchValue] = React.useState("");
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  const [open, setOpen] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [sort, setSort] = React.useState(1);


  const ref = React.useRef();

  const history = useHistory();

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const editClick = (item) => {
    let newFormValues = { ...props.formValues };
    newFormValues["id"] = item.id;
    newFormValues["name"] = item.name;
    newFormValues["bal"] = item.bal;

    newFormValues["item_id"] = item.id;
    props.setFormValues(newFormValues);
    props.setOpen(true);
  };

  const createBtnClick = (item) => {
    props.setOpen(true);
    props.resetForm();
  };

  const itemSort = () => {
    let newData = [...props.data];
    if (sort) {
      newData = newData.sort((a, b) => b.bal - a.bal);
    } else {
      newData = newData.sort((a, b) => b.bal + a.bal);
    }
    props.setData(newData);
    setSort(!sort);
  };

  const fetchData = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/incomes?search=" + searchValue + "&page=" + page,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        props.setItems(JSON.parse(res.items));
        setPageCount(res.pages);
        setTotal(res.total)
      });
  };

  const search = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchData();
  };

  const handleChoice = (choice) => {
    setOpen(false);
    if (choice) {
      sendDeleteRequest(rowToDelete);
      setRowToDelete({});
    }
  };

  const sendDeleteRequest = (item) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/income/" + item.id, {
      method: "DELETE",
      
        headers: {
          "x-access-token": tk,
        },
      
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchItems();
      });
  };

  return (
    <>
      <ResponisveDialog open={open} handleChoice={handleChoice} />
      <TableContainer className="table" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan="1" className="bold" align="left">
                <p className="td-p m0">Income</p>
                <p className="center m0">Total : {total}</p>
              </TableCell>
              <TableCell className="bold" align="center">
                <div className="search-div">
                  
                  <InputBase
                    ref={ref}
                    onKeyPress={(event) => search(event)}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                </div>
              </TableCell>
              <TableCell className="bold" align="center">
              { props.user.type == 'admin' && 
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<AddCircleIcon />}
                  onClick={createBtnClick}
                >
                  Create
                </Button>}
              </TableCell>
            </TableRow>
            <TableRow className="bold-row">
              <TableCell className="bold" align="center">
                NAME
              </TableCell>
              <TableCell onClick={itemSort} className="bold" align="center">
                BALANCE
              </TableCell>
              <TableCell className="bold" align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tbody-lg" >
            {props.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell className="hidden" component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">₹ {row.bal}</TableCell>

                <TableCell align="center" className="action-btn-td">
                  <AssignmentIcon onClick={() => history.push('income_ledger/'+row.id)} />
                  { props.user.type == 'admin' && <>
                  <EditIcon onClick={() => editClick(row)} />
                  <DeleteIcon
                    onClick={() => {
                      setOpen(true);
                      setRowToDelete(row);
                    }}
                  />
                  </>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={pageCount}
        color="primary"
        className="table"
        onChange={handlePageChange}
      />
    </>
  );
}
