import React from "react";

import TripExpenseTable from "./TripExpenseTable";
import TripExpenseForm from "./TripExpenseForm";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { BASE_URL , SEAANGEL } from "../../config";

const initFormValues = {
  id: "",
  quantity: "",
  rate: "",
  amount: "",
  details: "",
  trip_id: "",
  both: "no",
  expense_id: "",
  labour_expense_id: 0,
  isLabourLink: false,
  date: new Date(),
  img_src: "",
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ItemPage(props) {
  const [items, setItems] = React.useState([]);
  const [formValues, setFormValues] = React.useState(initFormValues);
  const [open, setOpen] = React.useState(false);

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [amount, setAmount] = React.useState(0);
  const [percent, setPercent] = React.useState(0);
  const [expenseID, setExpenseID] = React.useState(0);

  const [formMessage, setFormMessage] = React.useState("");
  const [formSeverity, setFormSeverity] = React.useState("success");

  React.useEffect(() => {
    let newFormValues = {...formValues};
    newFormValues['expense_id'] = expenseID;
    newFormValues['amount'] = (percent/100) * amount;
    setFormValues(newFormValues);
  }, [expenseID,percent])

  const fetchData = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_expenses?trip_id=" + props.trip_id, {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setItems(JSON.parse(res.items));
        if(SEAANGEL){
          props.setDiesel(JSON.parse(res.diesel));
          props.setKanni(JSON.parse(res.kanni));
          props.setKottu(JSON.parse(res.kottu));
          props.setWriter(JSON.parse(res.writer));
          props.setTandel(JSON.parse(res.tandel));
        }
      });
  };

  const resetForm = () => {
    setFormValues(initFormValues);
    setOpen(true);
  };

  return (
    <div>
      <div>
        <TripExpenseTable
          trip_id={props.trip_id}
          data={items}
          fetchItems={fetchData}
          setFormValues={setFormValues}
          formValues={formValues}
          setOpen={setOpen}
          resetForm={resetForm}
          setItems={setItems}
          setAmount={setAmount}
          setPercent={setPercent}
          setExpenseID={setExpenseID}
          user={props.user}
          diesel={props.diesel}
          tandel={props.tandel}
          kanni={props.kanni}
          kottu={props.kottu}
          writer={props.writer}
          setExpenseTotal={props.setExpenseTotal}
          setFinalTotal={props.setFinalTotal}
          expenseTotal={props.expenseTotal}
          purchaserTotal={props.purchaserTotal}
        />
      </div>
      

      <Modal
        style={{ overflow: "auto" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <div className="modal">
            <TripExpenseForm
              trip_id={props.trip_id}
              expenses={props.expenses}
              genExpenses={props.genExpenses}
              amount={amount}
              percent={percent}
              expenseID={expenseID}
              setPercent={setPercent}
              setAmount={setAmount}
              resetForm={resetForm}
              fetchItems={fetchData}
              setFormValues={setFormValues}
              formValues={formValues}
              setFormMessage={setFormMessage}
              setSnackBarOpen={setSnackBarOpen}
              setFormSeverity={setFormSeverity}
              setOpen={setOpen}
              fetchExpenses={props.fetchExpenses}
            />
          </div>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert onClose={() => setSnackBarOpen(false)} severity={formSeverity}>
          {formMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ItemPage;
