// DEV shahada 
// export const BASE_URL =  ""; 
// export const IMG_URL =  "";  
// export const SEAANGEL =  false;

// PROD shahada
// export const NAME =  "shahada.xyz";
// export const BASE_URL =  ""; 
// export const SEAANGEL =  false;
// export const IMG_URL =  "https://api.shahada.xyz"; 

// PROD kairagoa
export const NAME =  "kairagoa.com";
export const BASE_URL =  ""; 
export const SEAANGEL =  false;
export const IMG_URL =  "https://api.kairagoa.com"; 




















//PROD seaangel.xyz
// export const NAME =  "seaangel.xyz";
// export const SEAANGEL =  true;
// export const BASE_URL =  ""; 
// export const IMG_URL =  "http://api.seaangel.xyz";  

//DEV seaangel.xyz
// export const NAME =  "seaangel.xyz";
// export const BASE_URL =  ""; 
// export const IMG_URL =  "";  
// export const SEAANGEL =  true;

 