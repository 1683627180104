import React from "react";
import { BASE_URL } from "../../config";

import SeasonTable from "./SeasonTable";
import SeasonForm from "./SeasonForm";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import "./Season.css";

const initFormValues = {
  id:'',
  name:'',
  start_date:'',
  end_date:'',
  active:'',

};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ItemPage() {
  const [items, setItems] = React.useState([]);
  const [formValues, setFormValues] = React.useState(initFormValues);
  const [open, setOpen] = React.useState(false);

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [formMessage, setFormMessage] = React.useState("");
  const [formSeverity, setFormSeverity] = React.useState("success");

  const fetchItems = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/seasons")
      .then((res) => res.json())
      .then((res) => {
        setItems(JSON.parse(res.items));
      });
  };

  const resetForm = () => {
    setFormValues(initFormValues);
    setOpen(true);
  };

 

  return (
    <div>
      <div>
        <SeasonTable
          data={items}
          fetchItems={fetchItems}
          setFormValues={setFormValues}
          formValues={formValues}
          setOpen={setOpen}
          resetForm={resetForm}
          setItems={setItems}
        />
      </div>

      <Modal
        style={{ overflow: "auto" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <div className="modal" >
            <SeasonForm
              resetForm={resetForm}
              fetchItems={fetchItems}
              setFormValues={setFormValues}
              formValues={formValues}
              setFormMessage={setFormMessage}
              setSnackBarOpen={setSnackBarOpen}
              setFormSeverity={setFormSeverity}
              setSnackBarOpen={setSnackBarOpen}
              setOpen={setOpen}
            />
          </div>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert onClose={() => setSnackBarOpen(false)} severity={formSeverity}>
          {formMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ItemPage;
