import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";

export default function Report({ name, items, fetchItems, searchItems,isfishTripWise  }) {
  const [to, setTo] = React.useState("");
  const [from, setFrom] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState("all");

  let total = 0;

  const handelSelect = (value) => {
    fetchItems(from, to, value);
    setSelectedItem(value);
  }

  const renderDelauft = () => {
    let array = [];
    for (let key in items) {
      total += items[key].amount;
      array.push(
        <tr className="tr">
          <td colSpan="2" >
            {items[key].date && (
              <p className="m3">
                {new Date(items[key].date).toLocaleDateString("en-IN")}
              </p>
            )}
            <p className="m0 b">{items[key].name} </p>
           
            <p className="m3 b"> {items[key].trip} </p>
          </td>
          
          <td>₹ {items[key].amount}</td>
        </tr>
      );
    }
    return array;
  }

  const renderFishTrip = () => {
    
    let array = [];

    for (let key in items) {

      let fitems = []

      for (let item_key in items[key]['items']) {
        total += items[key]['items'][item_key].amount;
        fitems.push(
          <tr className="tr">
          <td>
            <p className="m0 b">{items[key]['items'][item_key].name} </p>
            
          </td>
          <td>
          <p className="m3">₹ {items[key]['items'][item_key].rate} </p>
          </td>

          <td>
            {items[key]['items'][item_key].quantity} {items[key]['items'][item_key].unit}
          </td>
          <td>₹ {items[key]['items'][item_key].amount}</td>
        </tr>
        )
      }

      fitems.push(
        <tr className="tr">
        <td>
          <p className="m0 b"> </p>
          
        </td>
        <td>
        <p className="m3"> </p>
        </td>

        <td>
         
        </td>
        <td>₹ {total}</td>
      </tr>
      )

      array.push(
        <>
        <tr className="tr">
          <td colSpan="4">
            <p className=" b f18">{items[key].trip} </p>
          </td>
          
        </tr>
        
        {fitems.map(item => item)}
        </>
        
      );
    }
    
    return array;
  }

  const renderItems = () => {
    total = 0;
    if (isfishTripWise){
      return renderFishTrip();
    }
    return renderDelauft();
  };

  renderItems();

  return (
    <div style={{ marginBottom: 150 }}>
      <table
        class="border-table"
        style={{ maxWidth: "900px", margin: "10px auto" }}
      >
        <tbody>
          <tr className="tr ">
            <td
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
              colSpan="3"
            >
              <select value={selectedItem} onChange={(e) => handelSelect(e.target.value)}>
              <option  value="all">all</option>
                {searchItems.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </td>
          </tr>
          
          <tr>
            <td
              colSpan="2"
              style={{
                textAlign: "center",
                padding: 10,
                fontSize: 30,
              }}
            >
              <AssignmentIcon /> {name}
            </td>
            <td
              style={{
                textAlign: "center",
                padding: 10,
                fontSize: 30,
              }}
            >
              <PrintIcon onClick={() => window.print() }className="print-icon" />
            </td>
          </tr>
          {renderItems()}
          <tr className="tr">
            <td></td>
            <td className="b f20">Total</td>
            <td className="b f20" style={{ padding: 10 }} colSpan="3">
              ₹ {total}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
