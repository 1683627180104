import React from "react";

import Report from "../common/Report";
import { BASE_URL } from "../../config";

export default function TripPurchaserReport() {
  const [items, setItems] = React.useState([]);
  const [searchItems, setSearchItems] = React.useState([]);

  React.useEffect(() => {
    fetchItems("", "", 'all');
    fetchSearchItems();
  }, []);

  const fetchItems = (from, to, id) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_purchaser/fish?to=" + to + "&from=" + from+ "&id=" + id,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setItems(res.items);
      });
  };
  const fetchSearchItems = (from, to) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/fishs?all=yes",{
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSearchItems(JSON.parse(res.items));
      });
  };

  return (
    <Report
      items={items}
      name="Fish Report"
      fetchItems={fetchItems}
      searchItems={searchItems}
    />
  );
}
