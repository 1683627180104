import React from "react";
import Book from "./../common/Book";
import { BASE_URL } from '../../config';

export default function ProfitBookPage() {
  const [ledger, setLedger] = React.useState([]);
  const [pos, setPos] = React.useState(0);
  const [neg, setNeg] = React.useState(0);
  const [prevNeg, setPrevNeg] = React.useState(0);
  const [prevPos, setPrevPos] = React.useState(0);
  const [day, setDay] = React.useState("");
  const [prev_season, setPrevSeason] = React.useState(0);


  React.useEffect(() => {
    fetchLedger("",0);
  }, []);

  const handleChange = (date, number) => {
    setDay(date);
    fetchLedger(date,number);
  };

  const fetchLedger = (date, number) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/profit_book?date="+date+"&num="+number,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLedger(res.ledger);
        setPos(res.pos);
        setNeg(res.neg);
        setPrevNeg(res.prev_neg);
        setPrevPos(res.prev_pos);
        setDay(res.date);
        setPrevSeason(res.prev_season);
      });
  };

  return (
    <Book
      handleChange={handleChange}
      pos={pos}
      ledger={ledger}
      neg={neg}
      book="Profit"
      day={day}
      prevNeg={prevNeg}
      prevPos={prevPos}
      prev_season={prev_season}
    />
  );
}
