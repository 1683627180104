import React from "react";


import ExpPurReport from '../common/ExpPurReport';
import { BASE_URL } from '../../config';

export default function PurchaserReport() {


  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    fetchItems("","");
  }, []);

  const fetchItems = (from, to) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/trip_purchaser_report?to="+to+"&from="+from,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setItems(res.items);
      });
  };

  return (
    <ExpPurReport items={items} name="Purchser Report" route="/trip_view/" fetchItems={fetchItems} />
  )
}
