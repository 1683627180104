import React from "react";

import { useParams } from "react-router-dom";
import Ledger from "../common/Ledger";
import { BASE_URL } from "../../config";

export default function ExpenseLedger() {
  const { partner_id } = useParams();

  const [ledger, setLedger] = React.useState([]);
  const [neg, setNeg] = React.useState("");
  const [pos, setPos] = React.useState("");
  const [name, setName] = React.useState("");
  const [prev_pos, setPrevPos] = React.useState("");
  const [prev_neg, setPrevNeg] = React.useState("");

  React.useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = (from, to) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(
      BASE_URL +
        "/api/partner_ledger/" +
        partner_id +
        "?to=" +
        to +
        "&from=" +
        from,
      {
        headers: {
          "x-access-token": tk,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLedger(res.ledger);
        setPos(res.pos);
        setNeg(res.neg);
        setName(res.name);
        setPrevPos(res.prev_pos);
        setPrevNeg(res.prev_neg);
      });
  };

  return (
    <Ledger
      prev_neg={prev_neg}
      prev_pos={prev_pos}
      name={name}
      pos={pos}
      neg={neg}
      ledger={ledger}
      fetchItems={fetchItems}
    />
  );
}
