import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { BASE_URL ,  SEAANGEL } from "../../config";

function SalaryPage(props) {
  const [items, setItems] = React.useState([]);
  const [amountPaid, setAmountPaid] = React.useState(0);
  const [expectedAmount, setExpectedAmount] = React.useState(0);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/salary", {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setItems(res.items);
        setAmountPaid(JSON.parse(res.amount_paid));
        setExpectedAmount(JSON.parse(res.expected_amount));
      });
  };

  const renderItems = () => {
    let listItems = [];
    for (const key in items) {
      listItems.push(
        <TableRow className="bold-row">
          <TableCell align="center">{items[key].name}</TableCell>
          <TableCell align="center">{items[key].months}</TableCell>
          <TableCell align="center">
            <p style={{margin: 0}} > Paid : {items[key].amount}</p>
            <p style={{margin: 0}} >Expected : {items[key].expected_salary}</p>
            <p style={{margin: 0, fontWeight: 'bold', fontSize: 20}} >Total : {items[key].expected_salary - items[key].amount}</p>
          </TableCell>
        </TableRow>
      );
    }
    return listItems;
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan="3" className="bold" align="center" style={{fontSize: 30}} >
                Salary
              </TableCell>
            </TableRow>
            <TableRow className="bold-row">
              <TableCell colSpan="3" align="center">
                <p>Paid : {amountPaid}</p>
                <p>Expected : {expectedAmount}</p>
                <p>Total : {expectedAmount - amountPaid}</p>
              </TableCell>
            </TableRow>
            <TableRow className="bold-row">
              <TableCell className="bold" align="center">
                Name
              </TableCell>
              <TableCell className="bold" align="center">
                Months
              </TableCell>
              <TableCell className="bold" align="center">
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderItems()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SalaryPage;
