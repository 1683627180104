import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FishPage from "./FishPage/FishPage";
import IncomePage from "./IncomePage/IncomePage";
import PurchaserPage from "./PurchaserPage/PurchaserPage";
import PartnerPage from "./PartnerPage/PartnerPage";
import EmployeePage from "./EmployeePage/EmployeePage";
import TripPage from "./TripPage/TripPage";
import TripView from "./TripPage/TripView";
import IncomePaymentPage from "./IncomePaymentPage/IncomePaymentPage";
import PurchaserPayment from "./PurchaserPaymentPage/PurchaserPaymentPage";
import ExpensePayment from "./ExpensePaymentPage/ExpensePaymentPage";
import PurchaserLedger from "./PurchaserPage/PurchaserLedger";
import IncomeLedger from "./IncomePage/IncomeLedger";
import ExpenseLedger from "./ExpensePage/ExpenseLedger";
import GeneralExpense from "./GeneralExpensePage/GeneralExpensePage";
import CashBookPage from "./CashBookPage/CashBookPage";
import ProfitBookPage from "./ProfitBookPage/ProfitBookPage";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import LoginForm from "./login/LoginForm";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableChartIcon from "@material-ui/icons/TableChart";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import TripPurchaserReport from "./TripPurchaserPage/TripPurchaserReport";
import ExpenseReport from "./ExpensePage/ExpenseReport";
import TripExpense from "./ExpensePage/TripExpense";
import GenExpense from "./ExpensePage/GeneralExpense";
import PurchaserReport from "./TripPurchaserPage/PurchaseReport";
import TripExpenseReport from "./TripPurchaserPage/TripExpenseReport";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import FishReportTripWise from "./TripPurchaserPage/FishReportTripWise";
import { BASE_URL } from "../config";
import HandLoanPartyPage from "./HandLoanPartyPage/HandLoanPartyPage";
import HandLoanLedger from "./HandLoanPartyPage/HandLoanLedger";
import HandLoanGivenPage from "./HandLoanGivenPage/HandLoanGivenPage";
import HandLoanTakenPage from "./HandLoanTakenPage/HandLoanTakenPage";
import PartnerPaymentPage from "./PartnerPaymentPage/PartnerPaymentPage";
import PartnerInvestmentPage from "./PartnerInvestmentPage/PartnerPaymentPage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SalaryPage from "./salary/SalaryPage";
import TandelPage from "./salary/TandelPage";
import SeasonPage from "./SeasonPage/SeasonPage";
import { NAME } from "../config";
import PartnerLedger from "./PartnerPage/PartnerLedger";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "600px",
    margin: "auto",
    backgroundColor: "whitesmoke",
  },
}));

export default function Admin(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [logedInUser, setLogedInUser] = React.useState({});
  const classes = useStyles();

  const getUser = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/get_user", {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.user) {
          setIsLoggedIn(true);
          setLogedInUser(JSON.parse(res.user));
        }
      });
  };

  React.useEffect(() => {
    getUser();
  }, []);

  if (!isLoggedIn) {
    return (
      <LoginForm
        setLogedInUser={setLogedInUser}
        setIsLoggedIn={setIsLoggedIn}
      />
    );
  }

  return (
    <div style={{ marginBottom: 100 }}>
      <Router>
        <Fab
          onClick={() => setOpen(true)}
          style={{ position: "fixed", bottom: "40px", right: "40px" }}
        >
          <MenuIcon />
        </Fab>
        <SwipeableDrawer
          anchor={"bottom"}
          open={open}
          onClick={() => setOpen(false)}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <div className={classes.root}>
            <Grid onClick={() => setOpen(false)} container spacing={3}>
              <Grid className="menu-header" item xs={12}>
                <p className="f20 bold">Master</p>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link className="nav-link" to="/trip_index">
                  <TableChartIcon />
                  <p className="m3">Trip</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/purchaser_index">
                  <TableChartIcon />
                  <p className="m3">Purcahser</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/trip_expense_index">
                  <TableChartIcon />
                  <p className="m3">Trip Expense</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/general_expense_index">
                  <TableChartIcon />
                  <p className="m3">General Expense</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/income_index">
                  <TableChartIcon />
                  <p className="m3">Income</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/fish_index">
                  <TableChartIcon />
                  <p className="m3">Fish</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/hand_loan_party">
                  <TableChartIcon />
                  <p className="m3">
                    HandLoan
                    <br /> Party
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/partner_index">
                  <TableChartIcon />
                  <p className="m3">Partner</p>
                </Link>
              </Grid>

              <Grid className="menu-div" item xs={3}>
                <Link to="/season_index">
                  <TableChartIcon />
                  <p className="m3">Season</p>
                </Link>
              </Grid>

              <Grid className="menu-header" item xs={12}>
                <p className="f20 bold">Payments</p>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/expense_payment">
                  <LocalAtmIcon />
                  <p className="m3">Trip Expense</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/general_expense">
                  <LocalAtmIcon />
                  <p className="m3">
                    General
                    <br /> Expense
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/income_payment">
                  <LocalAtmIcon />
                  <p className="m3">Income</p>
                </Link>
              </Grid>

              <Grid className="menu-div" item xs={3}>
                <Link to="/purchaser_payment">
                  <LocalAtmIcon />
                  <p className="m3">Purchser</p>
                </Link>
              </Grid>

              <Grid className="menu-div" item xs={3}>
                <Link to="/hand_loan_given">
                  <LocalAtmIcon />
                  <p className="m3">
                    HandLoan <br />
                    Given
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/hand_loan_taken">
                  <LocalAtmIcon />
                  <p className="m3">
                    HandLoan <br />
                    Taken
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/partner_payment">
                  <LocalAtmIcon />
                  <p className="m3">
                    Partner <br /> Payment
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/partner_investment">
                  <LocalAtmIcon />
                  <p className="m3">
                    Partner <br /> Investment
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-header" item xs={12}>
                <p className="f20 bold">Books</p>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/cash_book">
                  <MenuBookIcon />
                  <p className="m3">Cash</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/profit_book">
                  <MenuBookIcon />
                  <p className="m3">Profit</p>
                </Link>
              </Grid>
              <Grid className="menu-header" item xs={12}>
                <p className="f20 bold">Reports</p>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/fish_report">
                  <ReceiptIcon />
                  <p className="m3">Fish</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/salary">
                  <ReceiptIcon />
                  <p className="m3">Salary</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/tandel">
                  <ReceiptIcon />
                  <p className="m3">Tandel Paggi</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/fish_report_trip_wise">
                  <ReceiptIcon />
                  <p className="m3">
                    Fish <br />
                    Trip-Wise
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/expense_report">
                  <ReceiptIcon />
                  <p className="m3">Expense</p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/purchase_report">
                  <ReceiptIcon />
                  <p className="m3">
                    Trip
                    <br />
                    Purchase
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <Link to="/trip_expense_report">
                  <ReceiptIcon />
                  <p className="m3">
                    Trip <br />
                    Expense
                  </p>
                </Link>
              </Grid>
              <Grid className="menu-header" item xs={12}>
                <p className="f20 bold">Exit</p>
              </Grid>
              <Grid className="menu-div" item xs={3}>
                <a>
                  <ExitToAppIcon
                    onClick={() =>
                      window.localStorage.setItem("fish-token", "")
                    }
                  />
                  <p className="m3">Logout</p>
                </a>
              </Grid>
            </Grid>
          </div>
        </SwipeableDrawer>
        <Switch>
          <Route path="/fish_index">
            <FishPage user={logedInUser} />
          </Route>
          <Route path="/trip_expense_index">
            <TripExpense user={logedInUser} />
          </Route>
          <Route path="/general_expense_index">
            <GenExpense user={logedInUser} />
          </Route>
          <Route path="/income_index">
            <IncomePage user={logedInUser} />
          </Route>
          <Route path="/purchaser_index">
            <PurchaserPage user={logedInUser} />
          </Route>
          <Route path="/partner_index">
            <PartnerPage user={logedInUser} />
          </Route>
          <Route path="/income_index">
            <IncomePage user={logedInUser} />
          </Route>
          <Route path="/employee_index">
            <EmployeePage user={logedInUser} />
          </Route>
          <Route path="/trip_index">
            <TripPage user={logedInUser} />
          </Route>
          <Route path="/" exact>
            <TripPage user={logedInUser} />
          </Route>
          <Route path="/trip_view/:trip_id">
            <TripView user={logedInUser} />
          </Route>
          <Route path="/purchaser_payment">
            <PurchaserPayment user={logedInUser} />
          </Route>
          <Route path="/salary">
            <SalaryPage user={logedInUser} />
          </Route>
          <Route path="/tandel">
            <TandelPage />
          </Route>
          <Route path="/season_index">
            <SeasonPage user={logedInUser} />
          </Route>

          <Route path="/purchase_report">
            <PurchaserReport user={logedInUser} />
          </Route>
          <Route path="/fish_report_trip_wise">
            <FishReportTripWise user={logedInUser} />
          </Route>
          <Route path="/trip_expense_report">
            <TripExpenseReport user={logedInUser} />
          </Route>
          <Route path="/expense_payment">
            <ExpensePayment user={logedInUser} />
          </Route>
          <Route path="/general_expense">
            <GeneralExpense user={logedInUser} />
          </Route>
          <Route path="/partner_investment">
            <PartnerInvestmentPage user={logedInUser} />
          </Route>
          <Route path="/cash_book">
            <CashBookPage user={logedInUser} />
          </Route>
          <Route path="/profit_book">
            <ProfitBookPage user={logedInUser} />
          </Route>
          <Route path="/hand_loan_party">
            <HandLoanPartyPage user={logedInUser} />
          </Route>
          <Route path="/hand_loan_given">
            <HandLoanGivenPage user={logedInUser} />
          </Route>
          <Route path="/hand_loan_taken">
            <HandLoanTakenPage user={logedInUser} />
          </Route>
          <Route path="/income_payment">
            <IncomePaymentPage user={logedInUser} />
          </Route>
          <Route path="/partner_payment">
            <PartnerPaymentPage user={logedInUser} />
          </Route>
          <Route path="/fish_report">
            <TripPurchaserReport user={logedInUser} />
          </Route>
          <Route path="/expense_report">
            <ExpenseReport user={logedInUser} />
          </Route>
          <Route path="/purchaser_ledger/:purchaser_id">
            <PurchaserLedger user={logedInUser} />
          </Route>
          <Route path="/partner_ledger/:partner_id">
            <PartnerLedger user={logedInUser} />
          </Route>
          <Route path="/hand_loan_party_ledger/:party_id">
            <HandLoanLedger user={logedInUser} />
          </Route>
          <Route path="/income_ledger/:income_id">
            <IncomeLedger user={logedInUser} />
          </Route>
          <Route path="/expense_ledger/:expense_id">
            <ExpenseLedger user={logedInUser} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}
