import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Pagination from "@material-ui/lab/Pagination";
import { BASE_URL } from '../../config';


import ResponisveDialog from "./ResponsiveDialog";

export default function ItemTable(props) {
  const [searchValue, setSearchValue] = React.useState("");
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  const [open, setOpen] = React.useState(false);

  const ref = React.useRef();

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const editClick = (item) => {
    let newFormValues = { ...props.formValues };
    newFormValues["name"] = item.name;

    newFormValues["item_id"] = item.id;
    props.setFormValues(newFormValues);
    props.setOpen(true);
  };

  const createBtnClick = (item) => {
    props.setOpen(true);
    props.resetForm();
  };

  const fetchData = () => {

    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/employees?search=" + searchValue + "&page=" + page,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        props.setItems(JSON.parse(res.items));
        setPageCount(res.pages);
      });
  };

  const search = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchData();
  };

  const handleChoice = (choice) => {
    setOpen(false);
    if (choice) {
      sendDeleteRequest(rowToDelete);
      setRowToDelete({});
    }
  };

  const sendDeleteRequest = (item) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/employee/" + item.id, {
      method: "DELETE",
      
        headers: {
          "x-access-token": tk,
        },
      
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchItems();
      });
  };

  return (
    <>
      <ResponisveDialog open={open} handleChoice={handleChoice} />
      <TableContainer className="table" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan="1" className="bold" align="left">
                <p className="td-p m0">Employee</p>
                <p className="center m0">Total : {props.data.length}</p>
              </TableCell>
              <TableCell className="bold" align="center">
                <div className="search-div">
                  
                  <InputBase
                    ref={ref}
                    onKeyPress={(event) => search(event)}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                </div>
              </TableCell>
              <TableCell className="bold" align="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<AddCircleIcon />}
                  onClick={createBtnClick}
                >
                  Create
                </Button>
              </TableCell>
            </TableRow>
            <TableRow className="bold-row">
              <TableCell colSpan="2" className="bold" align="center">
                NAME
              </TableCell>
              <TableCell className="bold" align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tbody-lg" >
            {props.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell className="hidden" component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell colSpan="2" align="center">
                  {row.name}
                </TableCell>

                <TableCell align="center" className="action-btn-td">
                  <EditIcon onClick={() => editClick(row)} />
                  <VisibilityIcon />
                  <DeleteIcon
                    onClick={() => {
                      setOpen(true);
                      setRowToDelete(row);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={pageCount}
        color="primary"
        className="table"
        onChange={handlePageChange}
      />
    </>
  );
}
