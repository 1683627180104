import React from "react";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import PrintIcon from '@material-ui/icons/Print';
import ImageIcon from '@material-ui/icons/Image';

import {BASE_URL, IMG_URL} from '../../config';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";


import AssignmentIcon from '@material-ui/icons/Assignment';

export default function Book({
    neg,pos,ledger, prevPos, prevNeg, handleChange, day, book,prev_season
}) {

  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState({});

  const dateChange = (e, number) => {
    handleChange(e.target.value, number);
  }

  const imageClick = (item) => {
    setOpen(true);
    setItem(item);
  }

  return (
    <div>
      <Modal
        style={{ overflow: "auto" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={open}>
          <div className="modal" >
            <img src={IMG_URL+'/'+item.img_src} /> 
          </div>
        </Fade>
      </Modal>
      <table className="table" style={{ margin: "40px auto" }}>
        <tbody>
        <tr>
            <td
              colSpan="2"
              style={{
                textAlign: "center",
                padding: 5,
                fontSize: 30,
              }}
            >
             <AssignmentIcon /> {book} Book
            </td>
            <td style={{
                textAlign: "center",
                padding: 5,
                fontSize: 30,
              }}>
              <PrintIcon onClick={() => window.print() } className="print-icon"  />
            </td>
          </tr>
          <tr className="tr ">
            <td
              colspan="2"
              style={{ backgroundColor: "whitesmoke" }}
              className="f10 no-bd"
            >
              <p> Previous Sesason OB  </p>
            </td>
            <td
              style={{ backgroundColor: "whitesmoke" }}
              className=" f10  no-bd"
            >
              <p> ₹ {prev_season}</p>
            </td>
          </tr>
          <tr className="tr ">
            <td
              colspan="2"
              style={{ backgroundColor: "whitesmoke" }}
              className="f20   no-bd"
            >
              <p> Opening Balance  </p>
            </td>
            <td
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <p> ₹ {prevPos - prevNeg}</p>
            </td>
          </tr>
        <tr className="tr " >
            <td style={{textAlign: 'center'}} className="no-bd" >
            <ArrowLeftIcon className="rl-icon" onClick={() => handleChange(day, -1)} />
            
            </td>
            <td style={{textAlign: 'center'}} className="no-bd" >
              <input type="date"  style={{maxWidth: 100}} value={day} onChange={(e) => dateChange(e, 0) } />
            </td>
            <td style={{textAlign: 'center'}} className="no-bd" >
            <ArrowRightIcon className="rl-icon"  onClick={() => handleChange(day, 1)} />
           
            </td>
          </tr>
          {ledger.map((item) => {
            return (
              <tr className={item.isLeft ? "tr tr-right" : "tr tr-left"}>
                <td className="date-td">
                  <p className="f18 b m5">{item.name}</p>
                  <p className="f16 m5"> {new Date(item.date).toLocaleDateString('en-IN')}</p>
                  <p className="ext">
                    {item.fish}{" "}
                    {item.quantity && (
                      <>
                        {item.quantity} {item.unit}  ₹ {item.rate}
                      </>
                    )}
                  </p>
                  <p className="ext">{item.trip_name}</p>
                  {item.img_src ? <ImageIcon onClick={() => imageClick(item)} className="print-icon" /> : ''  }
                  <p className="grey m5">{item.details}</p>
                </td>
                {item.isLeft ? (
                  <>
                    <td>
                      <p className="amt"> ₹ {item.amount}</p>
                    </td>
                    <td></td>
                  </>
                ) : (
                  <>
                    <td></td>
                    <td>
                      <p className="amt"> ₹ {item.amount}</p>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
          <tr className="tr">
            <td></td>
            <td className="lg-amt green">₹ {pos}</td>
            <td className="lg-amt red">₹ {neg}</td>
          </tr>
          <tr className="tr">
            <td colSpan="3" className="lg-amt">
             ₹ {pos - neg }
            </td>
          </tr>
          <tr className="tr ">
            <td
              colspan="2"
              style={{ backgroundColor: "whitesmoke" }}
              className="f20   no-bd"
            >
              <p> Closing Balance (CB)  </p>
            </td>
            <td
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <p> ₹ {pos - neg + prevPos - prevNeg} </p>
            </td>
          </tr>
          <tr className="tr ">
            <td
              colspan="2"
              style={{ backgroundColor: "whitesmoke" }}
              className="f20   no-bd"
            >
              <p> CB + Prev Season </p>
            </td>
            <td
              style={{ backgroundColor: "whitesmoke" }}
              className=" f20  no-bd"
            >
              <p> ₹ {pos - neg + prevPos - prevNeg + prev_season} </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
