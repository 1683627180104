import React from "react";

import { useParams } from "react-router-dom";
import Ledger from '../common/Ledger';
import { BASE_URL } from '../../config';


export default function IncomeLedger() {
  const { income_id } = useParams();

  const [ledger, setLedger] = React.useState([]);
  const [neg, setNeg] = React.useState("");
  const [pos, setPos] = React.useState("");
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    fetchItems("", "");
  }, []);

  const fetchItems = (from , to) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/income_ledger/" + income_id + '?to='+to+'&from='+from,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLedger(res.ledger);
        setPos(res.pos);
        setNeg(res.neg);
        setName(res.name);
      });
  };

  return (
    <Ledger name={name} pos={pos} neg={neg} ledger={ledger} fetchItems={fetchItems} />
  )
}
