import React from "react";

import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BASE_URL } from "../../config";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import MUISelect from "@material-ui/core/Select";
import ExpenseCreate from "./../ExpensePage/ExpenseCreate";

export default function ItemForm({
  setFormValues,
  formValues,
  fetchItems,
  resetForm,
  setFormMessage,
  setFormSeverity,
  setSnackBarOpen,
  setOpen,
  expenses,
  trip_id,
  fetchExpenses,
  setPercent,
  percent,
  genExpenses,
}) {
  const [error, setError] = React.useState({});

  const [createExpenseOpen, setCreateExpenseOpen] = React.useState(false);

  const sendData = () => {
    let method = "POST";
    let data = new FormData();

    formValues.trip_id = trip_id;

    let newFormValues = { ...formValues };


    newFormValues.expense_id = newFormValues.expense_id.value;
    newFormValues.labour_expense_id = newFormValues.labour_expense_id.value;


    if (formValues.item_id) {
      method = "PUT";
    }

    newFormValues.date = changeFormDate(formValues.date);

    for (let key in newFormValues) {
      data.append(key, newFormValues[key]);
    }

    data.append(
      "image_file",
      document.getElementById("icon-button-file").files[0]
    );

    if (formValues.item_id) {
      method = "PUT";
    }

    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_expense", {
      method: method,
      body: data,
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        let verb = "Insert";
        if (formValues.item_id) {
          verb = "Update";
        }
        if (res.error) {
          setError(res.error);
          setFormMessage(verb + " Error.");
          setFormSeverity("error");
          setSnackBarOpen(true);
        } else {
          setFormMessage(verb + " Complete.");
          setFormSeverity("success");
          setSnackBarOpen(true);
          resetForm();
          setError({});
          fetchItems();
          setOpen(false);
        }
      });
  };

  const handelChange = (value, key) => {
    let altKey = "rate";
    if (key == "rate") {
      altKey = "quantity";
    }
    let amount = value * formValues[altKey];
    let newFormValues = { ...formValues };
    newFormValues[key] = value;
    newFormValues["amount"] = amount;
    setFormValues(newFormValues);
  };

  const expense_opt = expenses.map((item) => {
    return { value: item.id, label: item.name };
  });

  const gen_expense_opt = genExpenses.filter((item) => {
    if(item.name.toLowerCase()){
      return item; 
    }
  }).map((item) => {
    return { value: item.id, label: item.name };
  });

  const changeFormDate = (date) => {
    let trip_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return trip_date;
  };

  const handleFormChange = (key, value) => {
    let newFormValues = { ...formValues };
    newFormValues[key] = value;
    setFormValues(newFormValues);
  };

  return (
    <div>
      <ExpenseCreate
        fetchItems={fetchExpenses}
        open={createExpenseOpen}
        setOpen={setCreateExpenseOpen}
        isTrip={1}
      />

      <div onClick={() => setOpen(false)} className="paper">
        <CloseIcon className="close-btn" />
      </div>

      <div className="paper">
        <Button
          variant="contained"
          size="small"
          startIcon={<AddCircleIcon />}
          onClick={() => setCreateExpenseOpen(true)}
        >
          Create Expense
        </Button>
      </div>

      {percent > 0 && (
        <div className="paper">
          <TextField
            value={percent}
            onChange={(e) => setPercent(e.target.value)}
            className="input"
            label="Percent"
            variant="outlined"
          />
        </div>
      )}

      <div className="paper">
        <Select
          options={expense_opt}
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          onChange={(item) => handleFormChange("expense_id", item)}
          name="color"
          placeholder="Expense"
          value={formValues.expense_id}
        />
        {error["expense_id"]
          ? error.expense_id.map((e) => (
              <span style={{ fontSize: 12, color: "red" }}>{e}</span>
            ))
          : ""}
      </div>

      <div className="paper">
        <div style={{ flex: 1 }}>
          <FormControl
            className="input"
            variant="outlined"
          >
            <InputLabel>Link With Labour</InputLabel>
            <MUISelect
              value={formValues.isLabourLink}
              className="input"
              onChange={(e) => handleFormChange("isLabourLink", e.target.value)}
              label="Link With Labour"
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </MUISelect>
          </FormControl>
        </div>
      </div>

      { formValues.isLabourLink && <div className="paper">
        <Select
          options={gen_expense_opt}
          className="basic-single"
          classNamePrefix="select"
          isClearable={false}
          onChange={(item) => handleFormChange("labour_expense_id", item)}
          name="color"
          placeholder="Select Labour"
          value={formValues.labour_expense_id}
        />
       
      </div>}

      <div className="paper">
        <div style={{ display: "flex" }}>
          <div style={{ flex: 3 }}>
            <TextField
              value={formValues.quantity}
              error={error["quantity"] ? true : false}
              onChange={(e) => handelChange(e.target.value, "quantity")}
              className="input"
              label="Quantity"
              variant="outlined"
              helperText={
                error["quantity"]
                  ? error.quantity.map((e) => <span>{e}</span>)
                  : ""
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <FormControl
              error={error["unit"] ? true : false}
              className="input"
              variant="outlined"
            >
              <InputLabel>Unit</InputLabel>
              <MUISelect
                className="input"
                value={formValues.unit}
                onChange={(e) => handleFormChange("unit", e.target.value)}
                label="Unit"
                helperText={
                  error["unit"] ? error.unit.map((e) => <span>{e}</span>) : ""
                }
              >
                <MenuItem value="liter">liter</MenuItem>
                <MenuItem value="kg">kg</MenuItem>
                <MenuItem value="unit">unit</MenuItem>
                <MenuItem value="ton">ton</MenuItem>
                <MenuItem value="block">block</MenuItem>
              </MUISelect>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="paper">
        <div style={{ flex: 1 }}>
          <FormControl
            error={error["both"] ? true : false}
            className="input"
            variant="outlined"
          >
            <InputLabel>Credit/Both</InputLabel>
            <MUISelect
              className="input"
              value={formValues.both}
              onChange={(e) => handleFormChange("both", e.target.value)}
              label="Credit/Both"
              helperText={
                error["both"] ? error.both.map((e) => <span>{e}</span>) : ""
              }
            >
              <MenuItem value="no">Credit</MenuItem>
              <MenuItem value="yes">Both (Cash,Credit)</MenuItem>
            </MUISelect>
          </FormControl>
        </div>

        <div className="paper">
          <TextField
            value={formValues.rate}
            error={error["rate"] ? true : false}
            onChange={(e) => handelChange(e.target.value, "rate")}
            className="input"
            label="Rate"
            variant="outlined"
            helperText={
              error["rate"] ? error.rate.map((e) => <span>{e}</span>) : ""
            }
          />
        </div>
      </div>

      <div className="paper">
        <TextField
          value={formValues.amount}
          error={error["amount"] ? true : false}
          onChange={(e) => handleFormChange("amount", e.target.value)}
          className="input"
          label="Amount"
          variant="outlined"
          helperText={
            error["amount"] ? error.amount.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>
      <div className="paper">
        <TextField
          value={formValues.details}
          error={error["details"] ? true : false}
          onChange={(e) => handleFormChange("details", e.target.value)}
          className="input"
          label="Details"
          variant="outlined"
          helperText={
            error["details"] ? error.details.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>

      <div className="paper">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={error["date"] ? true : false}
            helperText={
              error["date"] ? error.date.map((e) => <span>{e}</span>) : ""
            }
            variant="outline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Trip Expense Date"
            value={formValues.date}
            onChange={(date) => handleFormChange("date", date)}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className="paper">
        <input
          accept="image/*"
          id="icon-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={(e) =>
            handleFormChange("img_src", URL.createObjectURL(e.target.files[0]))
          }
        />
        <img style={{ maxWidth: "200px" }} src={formValues["img_src"]} alt="" />
        <label htmlFor="icon-button-file">
          <InputLabel>Upload Image</InputLabel>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
        {error["img_src"]
          ? error.img_src.map((e) => (
              <span style={{ color: "red", fontSize: "12px" }}>{e}</span>
            ))
          : ""}
      </div>

      <div className="paper">
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={formValues.item_id ? <UpdateIcon /> : <SaveIcon />}
          onClick={sendData}
        >
          {formValues.item_id ? "Update" : "Save"}
        </Button>

        <div onClick={() => setOpen(false)} className="paper">
          <CloseIcon className="close-btn" />
        </div>
      </div>
    </div>
  );
}
