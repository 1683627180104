import React from "react";

import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { BASE_URL } from '../../config';

export default function ItemForm({
  setFormValues,
  formValues,
  fetchItems,
  resetForm,
  setFormMessage,
  setFormSeverity,
  setSnackBarOpen,
  setOpen,
}) {
  const [error, setError] = React.useState({});

  const sendData = () => {
    let method = "POST";
    let data = new FormData();

    let newFormValues = {...formValues};

    newFormValues.date = changeFormDate(formValues.date);

    for (let key in newFormValues) {
      data.append(key, newFormValues[key]);
    }

    if (formValues.item_id) {
      method = "PUT";
    }

    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL+"/api/trip", {
      method: method,
      body: data,
      
        headers: {
          "x-access-token": tk,
        },
      
    })
      .then((res) => res.json())
      .then((res) => {
        let verb = "Insert";
        if (formValues.item_id) {
          verb = "Update";
        }
        if (res.error) {
          setError(res.error);
          setFormMessage(verb + " Error.");
          setFormSeverity("error");
          setSnackBarOpen(true);
        } else {
          setFormMessage(verb + " Complete.");
          setFormSeverity("success");
          setSnackBarOpen(true);
          resetForm();
          setError({});
          fetchItems();
          setOpen(false);
        }
      });
  };

  const changeFormDate = (date) => {
    let trip_date = date.getFullYear()+'-'+(date.getMonth() + 1 )+'-'+date.getDate();
    return trip_date;
  };

  const handleFormChange = (key, value) => {
    let newFormValues = { ...formValues };
    newFormValues[key] = value;
    setFormValues(newFormValues);
  };

  return (
    <div>
      <div className="paper">
        <TextField
          value={formValues.name}
          error={error["name"] ? true : false}
          onChange={(e) => handleFormChange("name", e.target.value)}
          className="input"
          label="Name"
          variant="outlined"
          helperText={
            error["name"] ? error.name.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>

      <div className="paper">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={error["date"] ? true : false}
          helperText={
            error["date"] ? error.date.map((e) => <span>{e}</span>) : ""
          }
          variant="outline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Trip Date"
          value={formValues.date}
          onChange={(date) => handleFormChange("date", date)}
        />
        </MuiPickersUtilsProvider>
      </div>

      <div className="paper">
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={formValues.item_id ? <UpdateIcon /> : <SaveIcon />}
          onClick={sendData}
        >
          {formValues.item_id ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
}
