import React from "react";


function TandelPag2({data}) {


  return (
    <table>
      <tbody>
        <tr>
          <td class="bb llg">Purchaser</td>
          <td>{data["pur"].toLocaleString('en-in')}</td>
        </tr>
        <tr>
          <td class="bb llg">Diesel</td>
          <td>{data["diesel"].toLocaleString('en-in')}</td>
        </tr>
        <tr>
          <td class="bb llg">Ice</td>
          <td>{data["ice"].toLocaleString('en-in')}</td>
        </tr>
        <tr>
          <td></td>
          <td class="bbt b llg">{data["profit"].toLocaleString('en-in')}</td>
        </tr>
        <tr>
          <td>.</td>
          <td>.</td>
        </tr>
        <tr>
          <td class="b">18%</td>
          <td> {(data["profit"] * 0.18).toLocaleString('en-in')}</td>
        </tr>
        <tr>
          <td>.</td>
          <td>.</td>
        </tr>
        <tr>
          <td>.</td>
          <td>.</td>
        </tr>
        {data["tan"].map((item) => {
          return (
            <>
              <tr class="bbt">
                <td class="bb llg">{item[0]}</td>
                <td>{item[1].toLocaleString('en-in')}</td>
              </tr>
              <tr>
                <td class="">
                  Salary {item[3] * 100000} x {item[7]}
                </td>
                <td>{item[2].toLocaleString('en-in')}</td>
              </tr>
              <tr>
                <td></td>
                <td class="bbt">{item[4].toLocaleString('en-in')}</td>
              </tr>
              <tr>
                <td class="">Less Advance</td>
                <td>{item[5].toLocaleString('en-in')}</td>
              </tr>
              <tr>
                <td></td>
                <td class="bbt b llg">{item[6].toLocaleString('en-in')}</td>
              </tr>
              <tr>
                <td>.</td>
                <td>.</td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
}

export default TandelPag2;
