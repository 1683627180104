import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ImageIcon from "@material-ui/icons/Image";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import ResponisveDialog from "./ResponsiveDialog";
import { BASE_URL , IMG_URL } from "../../config";

export default function ItemTable(props) {
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [open, setOpen] = React.useState(false);

  const [imgOpen, setImgOpen] = React.useState(false);
  const [item, setItem] = React.useState({});

  const ref = React.useRef();

  React.useEffect(() => {
    fetchData();
  }, [props.trip_id]);

  const editClick = (item) => {
    let newFormValues = { ...props.formValues };
    newFormValues["quantity"] = item.quantity;
    newFormValues["rate"] = item.rate;
    newFormValues["amount"] = item.amount;
    newFormValues["date"] = new Date(item.date);
    newFormValues["trip_id"] = item.trip_id;
    newFormValues["purchaser_id"] = {
      value: item.purchaser.id,
      label: item.purchaser.name,
    };
    newFormValues["fish_id"] = { value: item.fish.id, label: item.fish.name };
    newFormValues["item_id"] = item.id;
    newFormValues["img_src"] = item.img_src;
    newFormValues["unit"] = item.unit;
    props.setFormValues(newFormValues);
    props.setOpen(true);
  };

  const createBtnClick = (item) => {
    props.setOpen(true);
    props.resetForm();
  };

  const fetchData = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_purchasers?trip_id=" + props.trip_id, {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        props.setItems(JSON.parse(res.items));
      });
  };

  const handleChoice = (choice) => {
    setOpen(false);
    if (choice) {
      sendDeleteRequest(rowToDelete);
      setRowToDelete({});
    }
  };

  const sendDeleteRequest = (item) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_purchaser/" + item.id, {
      method: "DELETE",

      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchItems();
        props.fetchTripCash();
      });
  };

  let total_amount = 0;

  return (
    <>
      <ResponisveDialog open={open} handleChoice={handleChoice} />
      <TableContainer >
      <Modal
          style={{ overflow: "auto" }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={imgOpen}
          onClose={() => setImgOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 200,
          }}
        >
          <Fade in={imgOpen}>
            <div className="modal">
              <img src={IMG_URL + "/" + item.img_src} />
            </div>
          </Fade>
        </Modal>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan="4" className="bold p0" align="center">
                <p style={{ fontSize: "30px", margin: "10px 5px" }}>
                  {props.trip.name}
                </p>
                <p style={{ fontSize: "20px", margin: "5px", color: "grey" }}>
                  {new Date(props.trip.date).toLocaleDateString("en-IN")}
                </p>
              </TableCell>
            </TableRow>
            {props.user.type == "admin" && (
              <TableRow>
                <TableCell colSpan="4" className="bold" align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddCircleIcon />}
                    onClick={createBtnClick}
                  >
                    Add Purchasers
                  </Button>
                </TableCell>
              </TableRow>
            )}
            <TableRow className="bold-row"></TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => {
              total_amount += row.amount;
              return (
                <TableRow key={row.id}>
                  <TableCell className="hidden" component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell className="p0" align="center">
                    <p className="b m3 f20">{row.purchaser.name}</p>
                    <p className="grey m3">
                      {new Date(row.date).toLocaleDateString("en-IN")}
                    </p>
                  </TableCell>
                  <TableCell className="p0" align="center">
                    <p className="m3">
                      {" "}
                      {row.quantity}
                      {row.unit} - ₹ {row.rate}{" "}
                    </p>
                    <p className="m3 f20 b green">₹ {row.amount} </p>
                    <p className="m5 f16">{row.fish.name}</p>
                     {row.img_src && <ImageIcon onClick={() => {setItem(row);setImgOpen(true)}} />} 
                  </TableCell>
                  <TableCell className="p0" align="center"></TableCell>

                  <TableCell
                    className="p0"
                    align="center"
                    className="action-btn-td"
                  >
                    {props.user.type == "admin" && (
                      <>
                        <EditIcon onClick={() => editClick(row)} />

                        <DeleteIcon
                          onClick={() => {
                            setOpen(true);
                            setRowToDelete(row);
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b">
                  {" "}
                  Cash : <span className="green"> ₹ {props.tripCash}</span>
                </p>
                {props.setPurchaserTotal(total_amount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b">
                  {" "}
                  Purchasers :{" "}
                  <span className="green">
                    {" "}
                    ₹ {total_amount - props.tripCash}
                  </span>
                </p>
                {props.setPurchaserTotal(total_amount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b">
                  {" "}
                  Total : <span className="green"> ₹ {total_amount}</span>
                </p>
                {props.setPurchaserTotal(total_amount)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
