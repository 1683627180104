import React from "react";

import Report from "../common/Report";
import { BASE_URL } from "../../config";

export default function ExpenseReport() {
  const [items, setItems] = React.useState([]);

  const [searchItems, setSearchItems] = React.useState([]);

  React.useEffect(() => {
    fetchItems("", "",'all');
    fetchSearchItems();
  }, []);

  const fetchItems = (from, to, id) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/expense/report?to=" + to + "&from=" + from+ "&id=" + id,
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setItems(res.items);
      });
  };

  const fetchSearchItems = (from, to) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/expenses?all=yes",
    {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        let new_items = (res.items);
        new_items.splice(0, 0,{id : 'trip', name: 'trip' });
        new_items.splice(0, 0,{id : 'general', name: 'general' });
        setSearchItems(new_items);
      });
  };

  return (
    <Report
      items={items}
      name="Expense Report"
      fetchItems={fetchItems}
      searchItems={searchItems}
    />
  );
}
