import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { BASE_URL , SEAANGEL, IMG_URL } from "../../config";
import TripExpRow from "../TripPage/TripExpRow";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import ResponisveDialog from "./ResponsiveDialog";

export default function ItemTable(props) {
  const [rowToDelete, setRowToDelete] = React.useState({});
  const [imgOpen, setImgOpen] = React.useState(false);
  const [item, setItem] = React.useState({});

  const [open, setOpen] = React.useState(false);

  const ref = React.useRef();

  const editClick = (item) => {
    let newFormValues = { ...props.formValues };
    newFormValues["quantity"] = item.quantity;
    newFormValues["rate"] = item.rate;
    newFormValues["amount"] = item.amount;
    newFormValues["details"] = item.details;
    newFormValues["trip_id"] = item.trip_id;
    newFormValues["unit"] = item.unit;
    newFormValues["expense_id"] = {
      label: item.expense.name,
      value: item.expense.id,
    };
    newFormValues["date"] = new Date(item.date);
    newFormValues["img_src"] = item.img_src;

    newFormValues["item_id"] = item.id;
    props.setFormValues(newFormValues);
    props.setOpen(true);
  };

  const createBtnClick = () => {
    props.setOpen(true);
    props.resetForm();
  };

  const specialExpenseClick = (percent, amount, id, name) => {
    props.setAmount(amount);
    props.setPercent(percent);
    props.setExpenseID({ value: id, label: name });
    createBtnClick();
  };

  React.useEffect(() => {
    props.fetchItems();
  }, [props.trip_id]);

  const handleChoice = (choice) => {
    setOpen(false);
    if (choice) {
      sendDeleteRequest(rowToDelete);
      setRowToDelete({});
    }
  };

  const sendDeleteRequest = (item) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_expense/" + item.id, {
      method: "DELETE",

      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        props.fetchItems();
      });
  };

  let total_amount = 0;
  return (
    <>
      <ResponisveDialog open={open} handleChoice={handleChoice} />
      <TableContainer>
        <Modal
          style={{ overflow: "auto" }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={imgOpen}
          onClose={() => setImgOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 200,
          }}
        >
          <Fade in={imgOpen}>
            <div className="modal">
              <img src={IMG_URL + "/" + item.img_src} />
            </div>
          </Fade>
        </Modal>
        <Table>
          <TableHead>
            {props.user.type == "admin" && (
              <TableRow>
                <TableCell colSpan="3" className="bold" align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddCircleIcon />}
                    onClick={createBtnClick}
                  >
                    Less Expense
                  </Button>
                </TableCell>
              </TableRow>
            )}
            <TableRow className="bold-row"></TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => {
              total_amount += row.amount;
              return (
                <TripExpRow
                  key={row.id}
                  setItem={setItem}
                  setImgOpen={setImgOpen}
                  exp={row}
                  editClick={editClick}
                  setOpen={setOpen}
                  setRowToDelete={setRowToDelete}
                  user={props.user}
                />
              );
            })}
            {SEAANGEL && props.setExpenseTotal(total_amount) }
            
            {SEAANGEL && (
              <>
                <TableRow>
                  <TableCell className="p0" colSpan="4" align="center">
                    .
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="p0" colSpan="4" align="center">
                    <p className="m3 f20 b green">
                      {" "}
                      Pruchaser :{" "}
                      <span className="green"> ₹ {props.purchaserTotal}</span>
                    </p>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="p0" colSpan="4" align="center">
                    <p className="m3 f20 b red">
                      {" "}
                      Expense :{" "}
                      <span className="red"> ₹ {props.expenseTotal}</span>
                    </p>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="p0" colSpan="4" align="center">
                    <p className="m3 f20 b">
                      {" "}
                      ₹{props.purchaserTotal - props.expenseTotal}
                    </p>
                  </TableCell>
                </TableRow>

                {props.kanni.amount ? (
                  <TripExpRow
                    msg="less"
                    exp={props.kanni}
                    editClick={editClick}
                    setOpen={setOpen}
                    setRowToDelete={setRowToDelete}
                    user={props.user}
                  />
                ) : (
                  <TableRow>
                    <TableCell colSpan="3" className="bold" align="center">
                      {props.user.type == "admin" && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<AddCircleIcon />}
                          onClick={() =>
                            specialExpenseClick(
                              2.5,
                              props.purchaserTotal - props.expenseTotal,
                              2,
                              "Kanni"
                            )
                          }
                        >
                          Less Kanni
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}

                {props.kottu.amount ? (
                  <TripExpRow
                    msg="less"
                    exp={props.kottu}
                    editClick={editClick}
                    setOpen={setOpen}
                    setRowToDelete={setRowToDelete}
                    user={props.user}
                  />
                ) : (
                  <TableRow>
                    <TableCell colSpan="3" className="bold" align="center">
                      {props.user.type == "admin" && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<AddCircleIcon />}
                          onClick={() =>
                            specialExpenseClick(
                              1.5,
                              props.purchaserTotal - props.expenseTotal - props.kanni.amount,
                              3,
                              "Kottu"
                            )
                          }
                        >
                          Less Kottu
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}

                {props.kanni.amount && props.kottu.amount && (
                  <TableRow>
                    <TableCell className="p0" colSpan="4" align="center">
                      <p className="m3 f20 b">
                        Total Fishing : ₹
                        {props.purchaserTotal -
                          props.expenseTotal -
                          props.kanni.amount -
                          props.kottu.amount}
                      </p>
                    </TableCell>
                  </TableRow>
                )}

                {props.writer.amount ? (
                  <TripExpRow
                    msg="less"
                    exp={props.writer}
                    editClick={editClick}
                    setOpen={setOpen}
                    setRowToDelete={setRowToDelete}
                    user={props.user}
                  />
                ) : (
                  <TableRow>
                    <TableCell colSpan="3" className="bold" align="center">
                      {props.user.type == "admin" && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<AddCircleIcon />}
                          onClick={() =>
                            specialExpenseClick(
                              6,
                              props.purchaserTotal -
                                props.expenseTotal -
                                props.kanni.amount -
                                props.kottu.amount,
                              5,
                              "Writer"
                            )
                          }
                        >
                          Less Writer
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}

                {props.writer.amount && (
                  <TableRow>
                    <TableCell className="p0" colSpan="4" align="center">
                      <p className="m3 f20 b">
                        {" "}
                        ₹
                        {props.purchaserTotal -
                          props.expenseTotal -
                          props.kanni.amount -
                          props.kottu.amount -
                          props.writer.amount}
                      </p>
                    </TableCell>
                  </TableRow>
                )}

                {props.tandel.amount ? (
                  <TripExpRow
                    msg="less"
                    exp={props.tandel}
                    editClick={editClick}
                    setOpen={setOpen}
                    setRowToDelete={setRowToDelete}
                    user={props.user}
                  />
                ) : (
                  <TableRow>
                    <TableCell colSpan="3" className="bold" align="center">
                      {props.user.type == "admin" && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<AddCircleIcon />}
                          onClick={() =>
                            specialExpenseClick(
                              22,
                              props.purchaserTotal -
                                props.expenseTotal -
                                props.kanni.amount -
                                props.kottu.amount -
                                props.writer.amount,
                              4,
                              "Tandel"
                            )
                          }
                        >
                          Less tandel
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                {props.tandel.amount && (
                  <TableRow>
                    <TableCell className="p0" colSpan="4" align="center">
                      <p className="m3 f20 b">
                        ₹
                        {props.purchaserTotal -
                          props.expenseTotal -
                          props.kanni.amount -
                          props.kottu.amount -
                          props.tandel.amount -
                          props.writer.amount}
                      </p>
                    </TableCell>
                  </TableRow>
                )}

                {props.writer.amount && (
                  <TripExpRow
                    msg="add"
                    exp={props.writer}
                    editClick={editClick}
                    setOpen={setOpen}
                    setRowToDelete={setRowToDelete}
                    user={props.user}
                  />
                )}

                {props.writer.amount && (
                  <TableRow>
                    <TableCell className="p0" colSpan="4" align="center">
                      <p className="m3 f20 b">
                        ₹
                        {props.purchaserTotal -
                          props.expenseTotal -
                          props.kanni.amount -
                          props.kottu.amount -
                          props.tandel.amount}
                      </p>
                    </TableCell>
                  </TableRow>
                )}

                {props.diesel.amount ? (
                  <TripExpRow
                    msg="less"
                    exp={props.diesel}
                    editClick={editClick}
                    setOpen={setOpen}
                    setRowToDelete={setRowToDelete}
                    user={props.user}
                  />
                ) : (
                  <TableRow>
                    <TableCell colSpan="3" className="bold" align="center">
                      {props.user.type == "admin" && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<AddCircleIcon />}
                          onClick={() => specialExpenseClick(0, 0, 1, "Diesel")}
                        >
                          Less Diesel
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}

                {props.diesel.amount && (
                  <TableRow>
                    <TableCell className="p0" colSpan="4" align="center">
                      <p className="m3 f20 b">
                        ₹
                        {props.purchaserTotal -
                          props.expenseTotal -
                          props.kanni.amount -
                          props.kottu.amount -
                          props.tandel.amount -
                          props.diesel.amount}
                      </p>
                    </TableCell>
                  </TableRow>
                )}
                {props.setFinalTotal(
                  props.purchaserTotal -
                    props.expenseTotal -
                    props.kanni.amount -
                    props.kottu.amount -
                    props.tandel.amount -
                    props.diesel.amount
                )}
              </>
            )}
            {!SEAANGEL && (
              <TableRow>
                <TableCell className="p0" colSpan="4" align="center">
                  <p className="m3 f20 b ">
                    {" "}
                    Expense : <span className="red"> ₹ {total_amount}</span>
                  </p>
                </TableCell>
                {props.setExpenseTotal(total_amount)}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
