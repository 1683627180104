import React from "react";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import Select from "@material-ui/core/Select";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BASE_URL } from "../../config";

export default function ItemForm({
  setFormValues,
  formValues,
  fetchItems,
  resetForm,
  setFormMessage,
  setFormSeverity,
  setSnackBarOpen,
  setOpen,
}) {
  const [error, setError] = React.useState({});

  const sendData = () => {
    let method = "POST";
    let data = new FormData();

    let newFormValues = { ...formValues };

    newFormValues.start_date = changeFormDate(formValues.start_date);
    newFormValues.end_date = changeFormDate(formValues.end_date);

    for (let key in newFormValues) {
      data.append(key, newFormValues[key]);
    }


    if (formValues.item_id) {
      method = "PUT";
    }

    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/season", {
      method: method,
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        let verb = "Insert";
        if (formValues.item_id) {
          verb = "Update";
        }
        if (res.error) {
          setError(res.error);
          setFormMessage(verb + " Error.");
          setFormSeverity("error");
          setSnackBarOpen(true);
        } else {
          setFormMessage(verb + " Complete.");
          setFormSeverity("success");
          setSnackBarOpen(true);
          resetForm();
          setError({});
          fetchItems();
          setOpen(false);
        }
      });
  };

  const changeFormDate = (date) => {
    let trip_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      return trip_date;
  };

  const handleFormChange = (key, value) => {
    let newFormValues = { ...formValues };
    newFormValues[key] = value;
    setFormValues(newFormValues);
  };

  return (
    <div>
      <div className="paper">
        <TextField
          value={formValues.name}
          error={error["name"] ? true : false}
          onChange={(e) => handleFormChange("name", e.target.value)}
          className="input"
          label="Name"
          variant="outlined"
          helperText={
            error["name"] ? error.name.map((e) => <span>{e}</span>) : ""
          }
        />
      </div>
      
      <div className="paper">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={error["start_date"] ? true : false}
            helperText={
              error["start_date"] ? error.start_date.map((e) => <span>{e}</span>) : ""
            }
            variant="outline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Start Date"
            value={formValues.start_date}
            onChange={(start_date) => handleFormChange("start_date", start_date)}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className="paper">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={error["end_date"] ? true : false}
            helperText={
              error["end_date"] ? error.end_date.map((e) => <span>{e}</span>) : ""
            }
            variant="outline"
            format="dd/MM/yyyy"
            margin="normal"
            label="End Date"
            value={formValues.end_date}
            onChange={(end_date) => handleFormChange("end_date", end_date)}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className="paper">
      <FormControl
          error={error["active"] ? true : false}
          className="input"
          variant="outlined"
        >
          <InputLabel>Active</InputLabel>
          <Select
            className="input"
            value={formValues.is_trip}
            onChange={(e) => handleFormChange("active", e.target.value)}
            label="Active"
            helperText={
              error["active"]
                ? error.active.map((e) => <span>{e}</span>)
                : ""
            }
          >
            <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Not Active</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="paper">
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={formValues.item_id ? <UpdateIcon /> : <SaveIcon />}
          onClick={sendData}
        >
          {formValues.item_id ? "Update" : "Save"}
        </Button>
      </div>
    
    </div>
  );
}
